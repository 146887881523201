<template>
  <div class="vip-container">
    <div class="banner">
      <div class="banner-container">
        <img src="@/assets/image/limitExempt/vip-bg.jpg" alt="" />
      </div>
      <div class="title banner-title">
        <div><span>开通会员</span><strong>全站正版资源任意选</strong></div>
        <i>MEMBERSHIP PACKAGE OPENING</i>
      </div>
    </div>
    <div class="vip-infos">
      <div
        v-for="i in packageList"
        :key="i.goods_id"
        class="packages-li"
        :class="{
          select: i.goods_id === selectPackage.goods_id
        }"
        @mouseenter="onMouse(i)"
        @mouseleave="onMouseLeve(i)"
      >
        <div class="package-title">
          <span>{{ i.name }}</span>
          <i>适用于{{i.goodTypeCustom == 'person' ? '个人' : '企业'}}用户</i>
          <div v-if="i.goodTypeCustom == 'person'"><span style="margin-top: 8px">不可</span><span>商用</span></div>
          <div v-else class="year"><span style="margin-top: 8px">商用</span><span>首选</span></div>
        </div>
        <div class="package-info-wrap">
          <div class="package-price">
            <p>
              <span>￥{{ Number(i.price) }}</span>{{ i.name == '月会员' ? '/月' : '/年' }}
            </p>
          </div>
          <div class="package-info" v-show="!i.isShow">
            <div class="list">
              <span>下载次数</span>
              <i>{{ i.down_count == 'n' ? '不限' : i.down_count }}</i>
            </div>
            <div class="list">
              <span>单日下载限制</span>
              <i>{{ i.down_num }}次</i>
            </div>
            <div class="list">
              <span>发票申请</span>
              <i>{{ i.goodTypeCustom == 'person' ? '普票' : '普票/专票'}}</i>
            </div>
          </div>
          <div class="package-info" v-show="i.isShow">
            <div class="list">
              <span v-if="i.goodTypeCustom == 'company'">自媒体</span>
              <i v-else>自媒体平台(中国)</i>
              <span><img class="icon_correct" src="@/assets/image/limitExempt/correct.svg"/></span>
            </div>
            <div class="list">
              <span v-if="i.goodTypeCustom == 'company'">网络直播/电台FM</span>
              <i v-else>个人直播/个人电台FM</i>
              <span><img class="icon_correct" src="@/assets/image/limitExempt/correct.svg"/></span>
            </div>
            <div class="list">
              <span v-if="i.goodTypeCustom == 'company'">企业盈利用途</span>
              <i v-else>产品内置用途</i>
              <span><img class="icon_correct" src="@/assets/image/limitExempt/correct.svg"/></span>
            </div>
            <div class="list">
              <span v-if="i.goodTypeCustom == 'company'">课程制作用途</span>
              <i v-else>个人作品</i>
              <span><img class="icon_correct" src="@/assets/image/limitExempt/correct.svg"/></span>
            </div>
            <div class="list">
              <span v-if="i.goodTypeCustom == 'company'">线下场所公播</span>
              <i v-else>家庭视频</i>
              <span><img class="icon_correct" src="@/assets/image/limitExempt/correct.svg"/></span>
            </div>
          </div>
        </div>
        <div class="pay">
          <p id="lijikaitong" data-agl-cvt="7" @click="immediatePayment(i)">立即订阅</p>
        </div>
      </div>
    </div>
    <div class="title">
      <strong>开通即享</strong>
      <i>PURCHASE MEMBERSHIP TO ENJOY</i>
    </div>
    <div class="infos-wrap">
      <div class="info-box">
        <div class="info-mask">
          <img src="@/assets/image/limitExempt/quanyi1_icon.svg" alt="" />
        </div>
        <h1>权益一</h1>
        <div class="content">
          <p>可授权以下渠道：</p>
          <p>自媒体平台（中国）、个人直播、电台、产品内置、个人作品、家庭视频等</p>
        </div>
      </div>
      <div class="info-box">
        <div class="info-mask">
          <img src="@/assets/image/limitExempt/quanyi2_icon.svg" alt="" />
        </div>
        <h1>权益二</h1>
        <div class="content">
          <p>人工定制选曲最多可提供15000首；</p>
          <p>享受一对一专属客服；</p>
        </div>
      </div>
      <div class="info-box">
        <div class="info-mask">
          <img src="@/assets/image/limitExempt/quanyi3_icon.svg" alt="" />
        </div>
        <h1>权益三</h1>
        <div class="content">
          <p>海量精选正版音乐、视频、音效、图片素材</p>
          <p>还可使用下载AI语音</p>
        </div>
      </div>
    </div>

    <!-- 选择支付方式 默认选中在线支付 -->
    <el-dialog
      class="payDiage"
      title="选择支付方式"
      :visible="dialogVisiblemember"
      :destroy-on-close="false"
      :show-close="true"
      :append-to-body="false"
      :before-close="handleBeforeClose"
      width="500px"
    >
      <el-radio-group
        v-model="payType"
        :disabled="packageList.length <= 0"
        class="online_payBOX"
      >
        <div>
          <el-radio :label="1" class="online_pay" border>在线支付</el-radio>
        </div>
      </el-radio-group>
      <!-- 二维码区域 在线支付-->
      <div v-if="payType === 1" class="qr-code-container">
        <div v-if="wxPayQrCode && wxPayQrCode !== ''" class="qr-code">
          <div class="scande_border">
            <vue-qrcode :value="wxPayQrCode" :width="130" :height="130" margin="auto" style="margin: 10px 10px" />
          </div>
          <p style="margin-top: 22px">
            <img src="@/assets/image/wxpic.png" alt="" style="margin: 2px 7px 0 0" />
            <span
              style="
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
                font-size: 12px;
              "
              >微信支付</span
            >
          </p>
        </div>
        <div v-if="aliPayQrCode && aliPayQrCode !== ''" class="qr-code">
          <div class="scande_border">
            <iframe
              id="bdIframe"
              :src="aliPayQrCode"
              frameborder="0"
              width="130"
              height="130"
              style="margin: 10px"
              transform="scale(.5, .5)"
              scrolling="no"
            />
          </div>
          <p style="margin-top: 22px">
            <img
              src="@/assets/image/alipaypic.png"
              alt=""
              style="margin: 2px 7px 0 0"
            />
            <span
              style="
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
                font-size: 12px;
              "
              >支付宝支付</span
            >
          </p>
        </div>
      </div>
    </el-dialog>

    <!-- 致用户的一封信 -->
    <Letter
      :showLetter="showLetter"
      v-show="showLetter"
      @closeLetterDialog="closeLetter"
    />
  </div>
</template>

<script>
import {
  fetchPackageList,
  fetchOrderPayByWxPay,
  fetchOrderPayByAliPay,
  fetchOrderPayStatusByPackage
} from '@/api/package'
import { send_eventApi } from '@/utils/common.js'
import { payReloadTime } from '@/config'
import { formDateTime } from '@/utils/formDate.js'
import Letter from '@/views/package/components/Letter'
import VueQrcode from '@/components/VueQrcode'
import { mapGetters } from 'vuex'

export default {
  name: 'LimitExemptVip',
  components: {
    Letter,
    VueQrcode
  },
  data() {
    return {
      packageList: [],
      selectPackage: {},
      dialogVisiblemember: false,
      payType: 1,
      wxPayQrCode: '', // 微信二维码
      aliPayQrCode: '', // 支付宝二维码
      showLetter: false // 是否显示致用户的一封信
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    }),
  },
  created() {
    this.getPackageList()
  },
  methods: {
    onMouse(i) {
      if (!i.isShow) {
        this.$set(i, 'isShow', false)
        i.isShow = !i.isShow
      } else {
        i.isShow = !i.isShow
      }
    },
    onMouseLeve(i) {
      if (!i.isShow) {
        this.$set(i, 'isShow', true)
        i.isShow = !i.isShow
      } else {
        i.isShow = !i.isShow
      }
    },
    /**
     * 关闭致用户的一封信
     */
    closeLetter() {
      this.showLetter = false
    },
    /**
     * 获取套餐列表
     * 2021-12-22 18:54:53
     * @author SnowRock
     */
    getPackageList() {
      fetchPackageList().then((res) => {
        const { data } = res
        let person =  data[1].filter((i) => i.name == '年会员')
        if(person.length) person[0].goodTypeCustom = 'person'
        let company =  data[2].filter((i) => i.name == '年会员')
        if(company.length) company[0].goodTypeCustom = 'company'
        this.packageList = person.concat(company)
      })
    },
    // 立即支付
    immediatePayment(i) {
      this.payGoodInfo = i
      const { goods_id } = i
      this.getPayQrCode(goods_id)
      this.dialogVisiblemember = true
    },
    // 立即开通 升级会员是从个人中心点击升级跳转过来的，这个页面不会有这个逻辑 23-05-18
    getPayQrCode(item) {
      //个人会员
      this.openMemberID = item
      const goods_id = item
      const params = {
        goods_id: goods_id,
        share_token: window.localStorage.getItem('share_token')
          ? window.localStorage.getItem('share_token')
          : ''
      }
      Promise.all([
        fetchOrderPayByWxPay(params),
        fetchOrderPayByAliPay(params)
      ]).then((res) => {
        this.wxPayOrderNo = res[0].data.order_no
        this.aliPayOrderNo = res[1].data.order_no
        this.wxPayQrCode = res[0].data.pay_url
        this.aliPayQrCode = res[1].data.pay_url
        //this.handleChangePayInfo()
        this.handleViewOrderPayStatus(this.wxPayOrderNo, 'wxPayTimer')
        this.handleViewOrderPayStatus(this.aliPayOrderNo, 'aliPayOrderTimer')
      })
    },
    /**
     * 处理支付方式发生改变时回调信息
     * 2021-12-22 20:58:45
     * @author SnowRock
     */
    handleChangePayInfo() {
      if (this.payType === 2) {
        this.handleClearCountDown('wxPayTimer')
        this.handleClearCountDown('aliPayOrderTimer')
      }
    },

    handleBeforeClose() {
      this.payType = 1
      this.dialogVisiblemember = false
      this.handleClearCountDown('aliPayOrderTimer')
      this.handleClearCountDown('wxPayTimer')
    },
    /**
     * 处理查看订单的支付状态
     * 2021-12-23 10:51:52
     * @author SnowRock
     */
    async handleViewOrderPayStatus(order_no, name) {
      // 进入即刻清除定时器
      try {
        const { data } = await fetchOrderPayStatusByPackage({
          order_no,
          share_token: window.localStorage.getItem('share_token')
            ? window.localStorage.getItem('share_token')
            : ''
        })
        // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
        if (data.status === -1) {
          // 添加定时器
          this[name] = setTimeout(() => {
            this.handleViewOrderPayStatus(order_no, name)
          }, payReloadTime)
          // this.$once('hook:beforeDestroy', () => {
          //   clearTimeout(this[name])
          //   this[name] = null
          // })
          return
        } else if (data.status === 0) {
          this.dialogVisiblemember = false
          this.handleClearCountDown('wxPayTimer')
          this.handleClearCountDown('aliPayOrderTimer')
          this.handleNavMember()
          this.$store.dispatch('user/getUserInfo')
          this.sendEventapi()
        }
        this.handleClearCountDown(name)
      } catch (e) {
        console.log(e)
        this.handleClearCountDown(name)
      }
    },
    /**
     * 事件埋点
     */
    sendEventapi() {
      const obj = {
        package_name: this.payGoodInfo.name,
        package_type: this.payGoodInfo.type == 1 ? '个人' : '企业',
        pay_type: this.payType == 1 ? '在线支付（支付宝/微信）' : '对公转账',
        op_date: formDateTime(),
        m_name: '',
        mid: '',
        reg_date: this.payGoodInfo.create_at,
        user_name: this.userInfo.name,
        user_id: this.userInfo.user_id
      }
      send_eventApi('e_pay', obj)
    },
    /**
     * 清除定时器
     */
    handleClearCountDown(name) {
      const timer = this[name]
      if (timer) {
        clearTimeout(timer)
        this[name] = null
      }
    },
    handleNavMember() {
      this.showLetter = true
    }
  }
}
</script>

<style lang="scss" scoped>
.vip-container {
  min-height: 100vh;
  min-width: 1000px;
  padding-bottom: 60px;

  .title {
    color: #333333;
    @include center-middle-flex($justify: center);
    flex-direction: column;
    div {
      display: flex;
      align-items: center;
    }
    strong {
      font-size: 36px;
      line-height: 50px;
      font-weight: 500;
    }
    i {
      font-style: normal;
      letter-spacing: 1px;
      line-height: 28px;
      font-size: 20px;
      color: #999999;
      margin-top: 8px;
    }
    span {
      margin-right: 12px;
      font-size: 38px;
      line-height: 56px;
    }
  }
  .banner {
    width: 100%;
    position: relative;
    .banner-container {
      width: 100%;
      height: calc(300px + 60px);
      padding-top: 60px;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .banner-title {
      position: absolute;
      top: 130px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .vip-infos {
    position: relative;
    top: -105px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    .packages-li {
      width: 580px;
      border-radius: 12px;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      .package-title {
        background-color: #fff;
        background: url('../../../assets/image/limitExempt/vip-card-bg.jpg')
        no-repeat;
        background-size: 100% 100%;
        @include size(100%, 105px);
        @include center-middle-flex($justify: center);
        flex-direction: column;
        position: relative;
        border-radius: 12px 12px 0 0;
        & > span {
          color: #333333;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 8px;
        }
        i {
          font-style: normal;
          letter-spacing: 4px;
          color: #999999;
          font-size: 14px;
        }
        div {
          @include size(50px, 67px);
          @include center-middle-flex($justify: flex-start);
          background: url('../../../assets/image/limitExempt/month.png') no-repeat;
          background-size: 100% 100%;
          flex-direction: column;
          position: absolute;
          right: 58px;
          top: -4px;
          span {
            color: #ffffff;
            font-size: 16px;
          }
        }
        div.year{
          background: url('../../../assets/image/limitExempt/year.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      .package-info-wrap {
        @include size(100%, 295px);
        padding: 0 40px;
        .package-price {
          font-family: alipuhuir;
          @include center-middle-flex($justify: center);
          @include size(100%, 79px);
          border-bottom: 1px solid;
          border-image: linear-gradient(
              90deg,
              #f2f2f2 0%,
              #c7c7c7 50%,
              #f2f2f2 100%
            )
            1 1;
          p {
            color: #999999;
            font-size: 24px;
          }
          span {
            margin-right: 4px;
            color: #ff2f0f;
            font-size: 60px;
            font-weight: bold;
            background: linear-gradient(180deg, #FF663F 0%, #FF2F0F 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .package-info {
          margin-top: 15px;
          flex-wrap: nowrap;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          color: #000000;
          line-height: 28px;
          opacity: 0.6;
          min-height: 200px;
          cursor: pointer;
          transition: 0.5s;

          span {
            display: inline-block;
            margin-left: 0.5em;
            font-size: 14px;
          }

          .list {
            display: flex;
            width: 80%;
            margin: 0 auto;
            font-size: 14px;
            justify-content: space-between;

            span {
              font-family: PingFangSC-Medium, PingFang SC;
              color: #666666;

              img {
                width: 20px;
              }
            }
            i {
              font-style: normal;
              color: #333333;
            }
          }

          p {
            margin-top: 0.8em;
          }
        }
      }
      .pay {
        cursor: pointer;
        width: 360px;
        height: 48px;
        border-radius: 24px;
        background-image: linear-gradient(135deg, #fe7273 0%, #fc4243 100%);
        box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.3);
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 18px;
        color: #ffffff;
        line-height: 48px;
        text-align: center;
      }
    }
    .packages-li:first-child{
      margin-right: 40px;
    }
  }
  .infos-wrap {
    margin-top: 60px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    .info-box {
      @include size(384px, 216px);
      position: relative;
      background-color: rgba(229, 42, 13, 0.05);
      margin-right: 24px;
      .info-mask {
        @include size(68px, 68px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -34px;
        background-color: #fff;
        border-radius: 50%;
        img {
          position: absolute;
          z-index: 20;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        &::after {
          content: '';
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #fdf4f3;
          position: absolute;
          bottom: 4px;
          left: 4px;
          z-index: 19;
        }
      }
      h1 {
        margin-top: 53px;
        margin-bottom: 18px;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        font-size: 20px;
        font-weight: 600;
      }
      .content {
        margin: 0 auto;
        padding: 0 16px;
        background-color: #ffffff;
        @include size(90%, 100px);
        @include center-middle-flex($justify: center);
        flex-direction: column;
        text-align: center;
        color: #666;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .info-box:last-child{
      margin-right: 0;
    }
  }
  .qr-code-container {
    display: flex;
    margin-left: 20px;

    .qr-code {
      padding: 20px;
      text-align: center;

      .code {
        width: 150px;
        height: 150px;
        background: #d8d8d8;
        border: 1px solid #979797;
        margin-bottom: 26px;
      }

      p {
        display: flex;
        justify-content: center;

        img {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .online_payBOX {
    display: flex;
    // justify-content: center;
    margin-left: 40px;

    &>div {
      width: 300px;
      display: flex;
      // justify-content: space-between;
    }
  }
  .scande_border {
    width: 150px;
    height: 150px;
    border: 1px solid #f5f5f5ff;
    border-radius: 2px;

    vue-qrcode {
      margin: auto;
      line-height: 130px;
    }
  }
}
</style>
